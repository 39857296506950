import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Cookies from "js-cookie";
import Logo from "./img/logo.svg";

export default function Header(props) {
  useEffect(() => {
    let session_token_from_cookie = Cookies.get("session_token");
    if (session_token_from_cookie) {
      props.setSessionToken(session_token_from_cookie);
    } else {
      props.setSessionToken(null);
    }
  }, [props]);

  return (
    <div className="navbar-wrapper">
      <div className="left-column">
        <Link className="logo-wrapper nav-item" to="/overview">
          <img src={Logo} alt="BHI Main Logo" height="100px"></img>
        </Link>
      </div>

      <div className="right-column">
        <NavLink to="/overview">
          <div className="page-link nav-item">OVERVIEW</div>
        </NavLink>

        <NavLink exact to="/login">
          <div className="page-link nav-item">LOGOUT</div>
        </NavLink>
      </div>
    </div>
  );
}
