import { useState, useEffect, useRef } from "react";

const useCurrencyInput = (cost) => {
  const [currentCost, setCurrentCost] = useState("");
  const lastCostKeyDown = useRef("Backspace");

  const handleCostKeyDown = (e) => {
    e.preventDefault();

    if (e.key === " ") return;

    let isBackspace = false;

    if (isNaN(e.key) && e.key !== "Backspace") {
      return;
    } else if (e.key === "Backspace") {
      isBackspace = true;
    } else if (isNaN(e.key)) {
      return;
    }

    lastCostKeyDown.current = e.key;

    setCurrentCost((pV) => {
      let splitString = [];
      if (!isBackspace) {
        if (pV === "0.00") {
          return `${pV.slice(0, 3)}${e.key}`;
        } else if (pV.at(0) === "0") {
          if (pV.includes("0.0")) {
            const leftOverValue = pV.slice(-1);
            return `${pV.slice(0, 2)}${leftOverValue}${e.key}`;
          } else {
            const leftOverValueOne = pV.slice(-2, -1);
            const leftOverValueTwo = pV.slice(-1);
            return `${leftOverValueOne}.${leftOverValueTwo}${e.key}`;
          }
        } else {
          splitString = currentCost.split("");
          splitString.splice(-3, 1);
          splitString.push(e.key);
          splitString.splice(-2, 0, ".");
        }
      } else {
        if (pV.at(0) === "0" && pV.includes("0.0")) {
          return "0.00";
        } else if (pV.at(0) === "0") {
          const leftOverValueOne = pV.slice(-2, -1);
          return `0.0${leftOverValueOne}`;
        } else {
          splitString = currentCost.split("");
          splitString.splice(-3, 1);
          splitString.pop();
          splitString.splice(-2, 0, ".");
        }
      }
      const filteredSplitString = splitString.filter((char) => char !== ",");

      const joinedString = filteredSplitString.join("");
      const formattedString = parseFloat(joinedString).toLocaleString("en", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      if (joinedString.at(0) === ".") {
        return `0${joinedString}`;
      }
      return formattedString;
    });
  };

  useEffect(() => {
    if (currentCost.trim().length <= 0) {
      setCurrentCost("0.00");
    }
    if (
      currentCost === "0.00" &&
      cost !== "0.00" &&
      lastCostKeyDown.current !== "Backspace"
    ) {
      setCurrentCost(cost);
    }

    if (currentCost !== cost) {
      if (currentCost === "0.00. " || currentCost === "0.00 ") {
        setCurrentCost("0.00");
      }
    }
  }, [currentCost, cost]);

  return { currentCost, setCurrentCost, handleCostKeyDown };
};

export default useCurrencyInput;
