import Cookies from "js-cookie";

export default function logout(props = null) {
  let body = {};
  let session_token = Cookies.get("session_token");
  if (session_token) {
    body["session_token"] = session_token;
  }
  let user_id = Cookies.get("user_id");
  if (user_id) {
    body["user_id"] = user_id;
  }
  Cookies.remove("bearer_token");
  Cookies.remove("user_role");
  Cookies.remove("user_name");
  Cookies.remove("auth_expires");

  if (props) {
    props.history.push("/login");
  }
}
