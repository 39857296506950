import {
  useContext,
  createContext,
  useReducer,
  useEffect,
  useState,
} from "react";

import asyncAPICall from "../util/apiWrapper";
import useAbortSignal from "../components/hooks/useAbortSignal";

import { me } from "../DefaultContainer";
import { issueReducer, initialJobs } from "../reducer/actions";

const IssuesContext = createContext();

export const IssuesContextProvider = ({ children }) => {
  const [issuesState, dispatch] = useReducer(issueReducer, initialJobs);
  const [isLoading, setIsLoading] = useState(false);

  const { signal } = useAbortSignal();
  const { user } = useContext(me);

  const handleDispatch = (type, field, value) => {
    dispatch({
      type,
      payload: {
        field,
        value,
      },
    });
  };

  const values = {
    issuesState,
    handleDispatch,
    isLoading,
  };

  useEffect(() => {
    setIsLoading(true);
    asyncAPICall(
      `api:W2oHkD04/parextractedissuesByOwner?owner=${user.employeeID}`,
      "GET",
      null,
      null,
      (data) => {
        handleDispatch("SET_JOBS", initialJobs, data);
        setIsLoading(false);
      },
      (err) => {
        if (!signal.aborted) {
          console.error("Error Getting Owner Full Name", err);
        }
        setIsLoading(false);
      },
      signal
    );
  }, [signal, user.employeeID]);

  return (
    <IssuesContext.Provider value={values}>{children}</IssuesContext.Provider>
  );
};

export const useIssuesData = () => {
  return useContext(IssuesContext);
};
