import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchBox = ({ onChange }) => {
  return (
    <div className="search-box-container">
      <FontAwesomeIcon className="phase-search-icon" icon="fa fa-search" />

      <input type="search" onChange={onChange} />
    </div>
  );
};

export default SearchBox;
