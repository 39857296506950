const ExternalLink = (props) => {
  const { name, link } = props;
  return (
    <a className="external-link" href={link} target="_blank" rel="noreferrer">
      {name}
    </a>
  );
};

export default ExternalLink;
