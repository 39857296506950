const Truncate = (props) => {
  const truncatedString =
    props?.content?.length > props.maxChar
      ? `${props.content.slice(0, props.maxChar)}${props.ellipses}`
      : props.content;

  return <div className="truncate-string">{truncatedString}</div>;
};

Truncate.defaultProps = {
  maxChar: 50,
  ellipses: "...",
};

export default Truncate;
