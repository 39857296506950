import { version as uuidVersion, validate as uuidValidate } from "uuid";

function validateUUID(uuid_string) {
  return uuidValidate(uuid_string) && uuidVersion(uuid_string) === 4;
}

function formatPhone(phoneNumber) {
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");

  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return phoneNumber;
}

const camelToTitleCase = (str) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export { validateUUID, camelToTitleCase, formatPhone };
