import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from "../components/modals/Modal";
import ExternalLink from "../components/core/ExternalLink";
import LoadingSpinner from "../helpers/LoadingSpinner";
import IssuesForm from "../components/forms/IssuesForm";

import { useIssuesData } from "../context/IssuesContext";

const OverviewPage = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState([]);
  const [jobsWithStatusCounts, setJobsWithStatusCounts] = useState([]);
  const [filterCategory, setFilterCategory] = useState("JobNumber");
  const [orderDescending, setOrderDescending] = useState(true);
  const [newIssueModal, setNewIssueModal] = useState(false);

  const statusFilter = "overview";

  const { issuesState, isLoading } = useIssuesData();

  const statusCounts = (arr) =>
    arr.reduce(
      (accumulator, currentValue) => {
        accumulator[currentValue.status] += 1;
        return accumulator;
      },
      { Pending: 0, Active: 0, Resolved: 0, Deleted: 0 }
    );

  useEffect(() => {
    const jobGroups = issuesState.reduce((group, jobInfo) => {
      const { job } = jobInfo;
      group[job] = group[job] ?? [];
      group[job].push(jobInfo);
      return group;
    }, {});

    const jobKeys = Object.keys(jobGroups);

    setJobsWithStatusCounts(
      jobKeys.map((job) => {
        return {
          [job]: {
            jobRows: jobGroups[job],
            statuses: { ...statusCounts(jobGroups[job]) },
          },
        };
      })
    );
  }, [issuesState]);

  useEffect(() => {
    if (searchTerm) {
      setFilter(
        jobsWithStatusCounts.filter(
          (job) =>
            job[Object.keys(job)].jobRows[0].jobName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            job[Object.keys(job)].jobRows[0].job.includes(searchTerm)
        )
      );
    } else {
      setFilter(jobsWithStatusCounts);
    }
  }, [searchTerm, jobsWithStatusCounts]);

  useEffect(() => {
    if (filterCategory && jobsWithStatusCounts.length > 0) {
      const jobsCopy = JSON.parse(JSON.stringify(jobsWithStatusCounts));

      jobsCopy.sort((a, b) =>
        compareCategories(a, b, filterCategory, orderDescending)
      );

      setFilter(jobsCopy);
    } else {
      setFilter(jobsWithStatusCounts);
    }
  }, [filterCategory, orderDescending, jobsWithStatusCounts]);

  const displayNumber = (num) => {
    return num > 0 ? num : "-";
  };

  const handleSort = (category) => {
    let isDescending = true;

    if (category === filterCategory && isDescending === orderDescending)
      isDescending = false;

    setFilterCategory(category);
    setOrderDescending(isDescending);
  };

  const getIsActiveIcon = (category) =>
    filterCategory === category && orderDescending
      ? "fa fa-caret-down"
      : "fa fa-caret-up";

  const compareCategories = (a, b, category, isDescending) => {
    let categoryA, categoryB;

    const jobNumA = Object.keys(a)[0];
    const jobNumB = Object.keys(b)[0];

    if (category === "JobNumber") {
      categoryA = jobNumA;
      categoryB = jobNumB;
    } else if (category === "Description") {
      categoryA = a[jobNumA].jobRows[0].jobName;
      categoryB = b[jobNumB].jobRows[0].jobName;
    } else if (category) {
      categoryA = a[jobNumA].statuses[category];
      categoryB = b[jobNumB].statuses[category];
    } else {
      return 0;
    }

    if (categoryA < categoryB) {
      return isDescending ? -1 : 1;
    }

    if (categoryA > categoryB) {
      return isDescending ? 1 : -1;
    }

    return 0;
  };

  const renderJobs = (jobsWithStatusCounts) => {
    return jobsWithStatusCounts.map((job) => {
      const jobNum = Object.keys(job);
      return (
        <div
          key={jobNum}
          className="job-wrapper"
          onClick={() => props.history.push(`/issues/${jobNum}/active`)}
        >
          <div className="row-start-text">{jobNum}</div>

          <div className="description">{job[jobNum].jobRows[0].jobName}</div>

          <div
            className="status selectable"
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/issues/${jobNum}/pending`);
            }}
          >
            {displayNumber(job[jobNum].statuses.Pending)}
          </div>

          <div className="status selectable">
            {displayNumber(job[jobNum].statuses.Active)}
          </div>

          <div
            className="status selectable"
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/issues/${jobNum}/resolved`);
            }}
          >
            {displayNumber(job[jobNum].statuses.Resolved)}
          </div>

          <div
            className="status selectable"
            onClick={(e) => {
              e.stopPropagation();
              props.history.push(`/issues/${jobNum}/deleted`);
            }}
          >
            {displayNumber(job[jobNum].statuses.Deleted)}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="overview-page-container">
      <div className="header-container">
        <div className="header">ISSUES OVERVIEW</div>

        <div className="link-wrapper">
          <ExternalLink
            name="Heatmap"
            link="https://bhico.domo.com/page/260140455"
          />

          <ExternalLink
            name="Detail"
            link="https://bhico.domo.com/page/1175768121"
          />

          <ExternalLink
            name="History"
            link="https://bhico.domo.com/page/1357542684"
          />
        </div>
      </div>

      <div className="search-wrapper">
        <div className="new-issue-text" onClick={() => setNewIssueModal(true)}>
          NEW ISSUE
        </div>

        <div className="search-bar">
          <FontAwesomeIcon icon="fa fa-magnifying-glass" />
          <input
            className="search-input"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Table..."
          />
        </div>
      </div>

      {issuesState.length > 0 ? (
        <div className="issues-wrapper">
          <div className="table-header-wrapper">
            <div
              className="row-start-text"
              onClick={() => handleSort("JobNumber")}
            >
              <FontAwesomeIcon icon={getIsActiveIcon("JobNumber")} />
              Job Number
            </div>

            <div
              className="description"
              onClick={() => handleSort("Description")}
            >
              <FontAwesomeIcon icon={getIsActiveIcon("Description")} />
              Description
            </div>

            <div className="status" onClick={() => handleSort("Pending")}>
              <FontAwesomeIcon icon={getIsActiveIcon("Pending")} />
              Pending
            </div>

            <div className="status" onClick={() => handleSort("Active")}>
              <FontAwesomeIcon icon={getIsActiveIcon("Active")} />
              Active
            </div>

            <div className="status" onClick={() => handleSort("Resolved")}>
              <FontAwesomeIcon icon={getIsActiveIcon("Resolved")} />
              Resolved
            </div>

            <div className="status" onClick={() => handleSort("Deleted")}>
              <FontAwesomeIcon icon={getIsActiveIcon("Deleted")} />
              Deleted
            </div>
          </div>

          {renderJobs(filter)}
        </div>
      ) : isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="issues-empty">There are no issues assigned to you.</div>
      )}

      <Modal
        isModalOpen={newIssueModal}
        onRequestClose={() => setNewIssueModal(false)}
        content={{ borderRadius: "0px" }}
      >
        <IssuesForm
          setIssuesModal={setNewIssueModal}
          statusFilter={statusFilter}
        />
      </Modal>
    </div>
  );
};

export default OverviewPage;
