import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faCloudSunRain,
  faExclamation,
  faExclamationCircle,
  faMinusSquare,
  faPhone,
  faSearch,
  faSpinner,
  faSquare,
  faTimes,
  faMagnifyingGlass,
  faCaretUp,
  faCaretDown,
  faCaretLeft,
} from "@fortawesome/free-solid-svg-icons";

const solidIcons = () => {
  return library.add(
    faSquare,
    faTimes,
    faSpinner,
    faCloudSunRain,
    faCheckCircle,
    faPhone,
    faSearch,
    faCircleNotch,
    faExclamation,
    faExclamationCircle,
    faChevronUp,
    faChevronDown,
    faMinusSquare,
    faMagnifyingGlass,
    faCaretUp,
    faCaretDown,
    faCaretLeft
  );
};

export default solidIcons;
