import { useState, useEffect, createContext } from "react";
import { Route } from "react-router-dom";

import Header from "./Header";
import Cookies from "js-cookie";
import logout from "./util/logout";
import Issues from "./pages/IssuesPage";
import Overview from "./pages/OverviewPage";
import asyncAPICall from "./util/apiWrapper";
import useAbortEffect from "./components/hooks/useAbortEffect";

import { IssuesContextProvider } from "./context/IssuesContext";

export const me = createContext(null);

const DefaultContainer = (props) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    let bearerTokenCookie = Cookies.get("bearer_token");
    let expiration = Cookies.get("auth_expires");
    let isExpired = Date.parse(expiration) < Date.now();
    if (!bearerTokenCookie || isExpired) {
      logout(props);
    }
  });

  useAbortEffect((signal) => {
    asyncAPICall(
      "api:KE7MCNYf/auth/me",
      "GET",
      null,
      null,
      (data) => {
        if (data) {
          Cookies.set("par_permission", data.parPermission);
          setUser(data);
        }
      },
      (err) => {
        if (!signal.aborted) {
          console.error("Error Getting User Credentials", err);
        }
      },
      signal,
      true
    );
  }, []);

  return (
    <div className="container">
      <Route
        path="/"
        render={(iprops) => (
          <Header
            {...iprops}
            sessionToken={props.sessionToken}
            setSessionToken={props.setSessionToken}
          />
        )}
      />
      <div className="body-wrapper">
        <me.Provider value={{ user, setUser }}>
          <IssuesContextProvider>
            <Route path="/overview" component={Overview} />
            <Route path="/issues/:job/:slug" component={Issues} />
          </IssuesContextProvider>
        </me.Provider>
      </div>
    </div>
  );
};

export default DefaultContainer;
