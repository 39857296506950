import { useEffect, useCallback } from "react";

import Cookies from "js-cookie";

const AuthPage = (props) => {
  const redirectUri = "http://localhost:3000/login/auth";
  const xanoOauthContinueUrl =
    "https://xytm-ohfx-fint.n7.xano.io/api:U0aE1wpF/oauth/google/continue?code=code&redirect_uri=redirect";

  const continueOauth = useCallback(
    (code) => {
      let fetchURL = new URL(xanoOauthContinueUrl);
      fetchURL.searchParams.set("redirect_uri", redirectUri);
      fetchURL.searchParams.set("code", code);
      fetchURL = fetchURL.toString();

      fetch(fetchURL)
        .then((res) => res.json())
        .then((data) => {
          Cookies.set("bearer_token", data.token);
          Cookies.set("auth_expires", Date.now() + 14400);
        })
        .then(() => props.history.push("/overview"))
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    [props.history]
  );

  useEffect(() => {
    const curUrl = new URL(document.location.href);
    const code = curUrl.searchParams.get("code");
    if (code) {
      continueOauth(code);
    }
  }, [continueOauth]);

  return <div className="show-loading"></div>;
};
export default AuthPage;
