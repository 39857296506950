export const initialJobs = [];

export const issueReducer = (issuesState, action) => {
  const { field, value } = action.payload;

  switch (action.type) {
    case "UPDATE_ISSUE":
      const jobIssues = [...issuesState];

      const issueToChange = jobIssues.find(({ issueNumber }) => {
        return issueNumber === field;
      });

      if (!issueToChange) {
        jobIssues.push(value);
        return jobIssues;
      }

      const index = jobIssues.findIndex(
        (issue) => issue.issueNumber === issueToChange.issueNumber
      );

      jobIssues[index] = value;

      return jobIssues;

    case "SET_JOBS":
      const initialJobsArray = [...issuesState];
      return [...initialJobsArray, ...value];

    default:
      throw new Error(`Invalid action type. ${action.type} not valid type`);
  }
};
