export const IdbInput = (props) => {
  const {
    title,
    subHeader = " ",
    type = "text",
    statusFilter,
    disabled = statusFilter === "resolved" || statusFilter === "deleted",
    name,
    value,
    onChange,
  } = props;

  return (
    <div className="title-and-field">
      <div className="sub-header">{subHeader}</div>
      <h4>{title}</h4>

      <input
        type={type}
        name={name}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export const IdbTextArea = (props) => {
  const {
    title,
    name,
    className = "textarea-field",
    statusFilter,
    disabled = statusFilter === "resolved" || statusFilter === "deleted",
    placeholder = "Describe the issue here:",
    value,
    onChange,
  } = props;
  return (
    <div className="title-textarea">
      <h4>{title}</h4>

      <textarea
        name={name}
        className={className}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export const IdbDisabledInput = (props) => {
  const { title, subHeader = " ", className = "disabled", value } = props;
  return (
    <div className="title-and-field">
      <div className="sub-header">{subHeader}</div>
      <h4>{title}</h4>
      <div className={className}>{value}</div>
    </div>
  );
};

export const IdbSelect = (props) => {
  const {
    title,
    name,
    subHeader = " ",
    statusFilter,
    disabled = statusFilter === "resolved" || statusFilter === "deleted",
    onChange,
    value,
    options,
  } = props;
  return (
    <div className="title-and-field">
      <div className="sub-header">{subHeader}</div>
      <h4>{title}</h4>

      <select name={name} disabled={disabled} onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const IdbModal = (props) => {
  const {
    title,
    subHeader = " ",
    statusFilter,
    className = `${
      statusFilter === "resolved" || statusFilter === "deleted"
        ? "disabled"
        : "open-modal"
    }`,
    onClick,
    value,
  } = props;
  return (
    <div className="title-and-field">
      <div className="sub-header">{subHeader}</div>
      <h4>{title}</h4>

      <div className={className} onClick={onClick}>
        {value}
      </div>
    </div>
  );
};

export const IdbCurrency = (props) => {
  const {
    title,
    subHeader = " ",
    statusFilter,
    disabled = statusFilter === "resolved" || statusFilter === "deleted",
    currencySign,
    placeholder = "0.00",
    type = "tel",
    min = "0.00",
    step = "0.01",
    onKeyDown,
    onChange,
    value,
  } = props;
  return (
    <div className="title-and-field">
      <div className="sub-header">{subHeader}</div>
      <h4>{title}</h4>
      <div
        className={`currency-wrapper ${
          (statusFilter === "resolved" || statusFilter === "deleted") &&
          "disabled"
        }`}
      >
        <div className="dollar-sign">{currencySign}</div>

        <input
          className="currency"
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          min={min}
          step={step}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
