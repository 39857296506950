import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAbortSignal from "../components/hooks/useAbortSignal";
import AddNewIssueButton from "../components/core/AddNewIssueButton";
import IssuesForm from "../components/forms/IssuesForm";
import Modal from "../components/modals/Modal";
import asyncAPICall from "../util/apiWrapper";

import { useIssuesData } from "../context/IssuesContext";
import { me } from "../DefaultContainer";

const priorityDict = {
  "": 0,
  Low: 1,
  Medium: 2,
  High: 3,
};

const IssuesPage = (props) => {
  const { issuesState } = useIssuesData();

  const [filteredIssues, setFilteredIssues] = useState([]);
  const [issueSearchTerm, setIssueSearchTerm] = useState("");
  const [issueNum, setIssueNum] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [isDescending, setIsDescending] = useState(true);
  const [issuesModal, setIssuesModal] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [jobDesc, setJobDesc] = useState("");

  const { signal } = useAbortSignal();
  const { user } = useContext(me);

  const jobNum = props.match.params.job;
  const statusFilter = props.match.params.slug;

  const handleSort = (sortByField) => {
    setIsDescending(
      filterCategory === sortByField && isDescending ? false : true
    );
    setFilterCategory(sortByField);
  };

  const handleSetIssueNum = (issue) => {
    setIssuesModal(true);
    setIssueNum(issue);
  };

  const getArrowIconDirection = (category) =>
    category === filterCategory && isDescending
      ? "fa fa-caret-down"
      : "fa fa-caret-up";

  useEffect(() => {
    if (issuesState.length > 0) {
      const jobIssues = issuesState.filter((job) => job.job === jobNum);

      if (statusUpdate) {
        setStatusUpdate(false);
      }

      let filteredIssuesByStatus = jobIssues.filter(
        (issue) => issue.status.toLowerCase() === statusFilter
      );

      let sortedList = [...filteredIssuesByStatus];
      if (filterCategory.length) {
        if (filterCategory === "priority") {
          sortedList = filteredIssuesByStatus.sort((a, b) => {
            if (priorityDict[a.priority] < priorityDict[b.priority]) {
              return -1;
            }
            if (priorityDict[a.priority] > priorityDict[b.priority]) {
              return 1;
            }
            return 0;
          });
        } else if (
          filterCategory === "dateRaised" ||
          filterCategory === "targetDate"
        ) {
          sortedList = filteredIssuesByStatus.sort((a, b) => {
            a = a[filterCategory].split("-");
            b = b[filterCategory].split("-");
            return a[2] - b[2] || a[0] - b[0] || a[1] - b[1];
          });
        } else {
          sortedList = filteredIssuesByStatus.sort((a, b) => {
            if (a[filterCategory] < b[filterCategory]) {
              return -1;
            }
            if (a[filterCategory] > b[filterCategory]) {
              return 1;
            }
            return 0;
          });
        }
      }

      if (isDescending) {
        sortedList = sortedList.reverse();
      }

      if (issueSearchTerm.length) {
        sortedList = sortedList.filter(
          (issueObj) =>
            issueObj.title
              .toLowerCase()
              .includes(issueSearchTerm.toLowerCase()) ||
            issueObj.phaseCode.includes(issueSearchTerm)
        );
      }

      setFilteredIssues(sortedList);
    }
  }, [
    issuesState,
    jobNum,
    statusFilter,
    statusUpdate,
    filterCategory,
    issueSearchTerm,
    isDescending,
  ]);

  useEffect(() => {
    asyncAPICall(
      `api:KE7MCNYf/vprojectswithid_by_Project?project=${jobNum}`,
      "GET",
      null,
      null,
      (data) => {
        setJobDesc(data[0].Project_Name);
      },
      (err) => {
        if (!signal.aborted) {
          console.error("Error Getting Job Description", err);
        }
      },
      signal
    );
  }, [signal, jobNum]);

  const renderIssues = () => {
    return filteredIssues.map((issue) => {
      return (
        <div
          key={issue.issueNumber}
          className="job-wrapper"
          onClick={() => handleSetIssueNum(issue.issueNumber)}
        >
          <div className="row-start-text">
            {issue.phaseCode ? issue.phaseCode : "All Phases"}
          </div>

          <div className="column-text">{issue.title ? issue.title : "N/A"}</div>

          <div className="column-text">{user.name ? user.name : "N/A"}</div>

          <div className="column-text">
            {issue.priority ? issue.priority : "N/A"}
          </div>

          <div className="column-text">
            {issue.dateRaised ? issue.dateRaised : "N/A"}
          </div>

          <div className="column-text">
            {issue.targetDate ? issue.targetDate : "N/A"}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="issues-page-container">
      <div className="header">{statusFilter.toUpperCase()} ISSUES</div>

      <div className="action-wrapper">
        <div
          className="overview"
          onClick={() => props.history.push("/overview")}
        >
          <FontAwesomeIcon icon="fa fa-caret-left" />
          Overview
        </div>

        <div
          className={`action ${statusFilter === "new" ? "selected" : ""}`}
          onClick={() => {
            props.history.push(`/issues/${jobNum}/new`);
          }}
        >
          NEW ISSUE
        </div>

        <div
          className={`action ${statusFilter === "pending" ? "selected" : ""}`}
          onClick={() => props.history.push(`/issues/${jobNum}/pending`)}
        >
          PENDING
        </div>

        <div
          className={`action ${statusFilter === "active" ? "selected" : ""}`}
          onClick={() => props.history.push(`/issues/${jobNum}/active`)}
        >
          ACTIVE
        </div>

        <div
          className={`action ${statusFilter === "resolved" ? "selected" : ""}`}
          onClick={() => props.history.push(`/issues/${jobNum}/resolved`)}
        >
          RESOLVED
        </div>

        <div
          className={`action ${statusFilter === "deleted" ? "selected" : ""}`}
          onClick={() => props.history.push(`/issues/${jobNum}/deleted`)}
        >
          DELETED
        </div>
      </div>

      <div className="search-wrapper">
        <div className="job-number">
          <strong>{jobNum}</strong> {jobDesc}
        </div>

        <div className="search-bar">
          <FontAwesomeIcon icon="fa fa-magnifying-glass" />
          <input
            className="search-input"
            placeholder="Search Table..."
            onChange={(e) => setIssueSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {filteredIssues.length ? (
        <div className="issues-wrapper">
          <div className="table-header-wrapper">
            <div
              className="row-start-text"
              onClick={() => handleSort("phaseCode")}
            >
              <FontAwesomeIcon icon={getArrowIconDirection("phaseCode")} />
              Phase Code
            </div>

            <div className="column-text" onClick={() => handleSort("title")}>
              <FontAwesomeIcon icon={getArrowIconDirection("title")} />
              Issue Title
            </div>

            <div className="column-text">Issue Owner</div>

            <div className="column-text" onClick={() => handleSort("priority")}>
              <FontAwesomeIcon icon={getArrowIconDirection("priority")} />
              Priority
            </div>

            <div
              className="column-text"
              onClick={() => handleSort("dateRaised")}
            >
              <FontAwesomeIcon icon={getArrowIconDirection("dateRaised")} />
              Date Raised
            </div>

            <div
              className="column-text"
              onClick={() => handleSort("targetDate")}
            >
              <FontAwesomeIcon icon={getArrowIconDirection("targetDate")} />
              Target Date
            </div>
          </div>

          {renderIssues()}
        </div>
      ) : (
        <div
          className={
            statusFilter === "new" ? "new-issue-button-wrapper" : "issues-empty"
          }
          onClick={statusFilter === "new" ? () => setIssuesModal(true) : null}
        >
          {statusFilter === "new" ? (
            <AddNewIssueButton />
          ) : (
            "There are no issues assigned to you."
          )}
        </div>
      )}
      <Modal
        isModalOpen={issuesModal}
        onRequestClose={() => setIssuesModal(false)}
        content={{ borderRadius: "0px" }}
      >
        <IssuesForm
          statusFilter={statusFilter}
          issueNum={issueNum}
          jobNum={jobNum}
          jobDesc={jobDesc}
          setIssuesModal={setIssuesModal}
        />
      </Modal>
    </div>
  );
};

export default IssuesPage;
